import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import Brand from '../components/shared/Brand'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg,
	ArticleImg,
	ArticleList,
	ArticleListItem,
	ArticlePage
} from '../components/common/Article'
import { graphql } from 'gatsby'
import AnchorLink from '../components/shared/AnchorLink'
import RouterLink from '../components/shared/RouterLink'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function BenefitsOfTimeManagement(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "9 Top Benefits of Time Management";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"9 Top Benefits of Time Management - " + productName}
				description={"There are many benefits of time management. We discuss our top nine."}
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Benefits of Time Management"
					caption={<UnsplashPhotoCredit author="Cassie Boca" href="https://unsplash.com/@cassieboca"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>
					<ArticleCopy>
						Are you a busy person with lots to do and little time to do it? If so, then
						you already know that managing one's time is important. But you might not be
						aware of all the benefits that time management can offer. By using LogSpace
						for our own time management, we have enjoyed several of these benefits. So
						we'd like to explore with you the top 9 benefits of time management now.
					</ArticleCopy>

					<ArticleList gutters>
						<ArticleListItem title="Creates more opportunities">
							Taking on new clients or projects is good news. You would like to be
							able to be confident when you do this. But if you aren't sure your
							current workload has room for the next big thing, you may miss out on an
							opportunity. Having a good pulse on your time can let you answer with
							confidence because you'll know how much time you've got.
						</ArticleListItem>

						<ArticleListItem title="Reduces stress">
							We all know stress is bad for our health. Uncertainty about the future
							can be a cause of stress. And your ability to be certain about decisions
							you make depends on the information you have. Accurate time keeping can
							help. You can use those records to make informed decisions with a
							greater degree of confidence.
						</ArticleListItem>

						<ArticleListItem title="Helps reach your goals">
							If you use a time management system, you'll have a record of where
							you've spent your time. You can use this to keep track of your progress
							on projects. Having a record of your progress can help you reach your
							goal of completing the project. It can serve as a motivator to see the
							project to the end. Also, you can use insights from time log reports to
							help plan your approach to reach the finish line.
						</ArticleListItem>

						<ArticleImg
							alt="Reaching Goals"
							caption={<UnsplashPhotoCredit author="Benjamin Voros" href="https://unsplash.com/@vorosbenisop"/>}
							imgData={data.mountainImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem title="Boosts productivity">
							Time tracking systems, like a <RouterLink to="/timesheet-app/">timesheet app</RouterLink>,
							keep a record of how much time you spent and for what purpose.
							Use these insights for good time management and to increase workflow
							efficiency.
						</ArticleListItem>

						<ArticleListItem title="Lets you stand out from the competition">
							Clients like to feel confident that their contractors are getting the
							job done. One way you can help them see your progress is to provide
							accurate time log reports. You can also send professional
							{' '}<RouterLink to="/features/invoicing/">invoices</RouterLink>. Some
							time management systems (such as <Brand/>) can create these.
							Combine these tools with your time management skills to help put your
							best foot forward and rise above the competition.
						</ArticleListItem>

						<ArticleListItem title="Helps manage distractions">
							One of the many important benefits of time management is to reduce the
							endless source of distractions that surround us in our day-to-day life.
							This is crucial to staying focused on projects and meeting your
							deadlines.
						</ArticleListItem>

						<ArticleImg
							alt="Distractions"
							caption={<UnsplashPhotoCredit author="Lavi Perchik" href="https://unsplash.com/@laviperchik"/>}
							imgData={data.fireworksImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem title="Decreases wasted time">
							Modern time tracking systems can record your time with the press of a
							button. Gone are the days of needing to keep confusing spreadsheets or
							worse—hand written notes. Systems like LogSpace can create invoices and
							{' '}<RouterLink to="/features/reporting/">reports</RouterLink> for
							you based on the logs you've entered. How's that for saving time!
						</ArticleListItem>

						<ArticleListItem title="Reduces brain fog">
							Most people can only keep track of a certain number of things in their
							memory at a time. Reducing the number of items you need to think about
							is a way to keep your mind clear. With less to think about, you can
							focus on what's important. Using a time management system can help by
							keeping track of your time so you don't have to spend so much brain
							power on it.
						</ArticleListItem>

						<ArticleListItem title="Strengthens your reputation">
							Effective time management can help you make the most of your time.
							Because of this, you can have an advantage when working on your
							projects. And if these projects are for clients, they will be glad to
							see that you've done an efficient job. Keep that up and you can build a
							good reputation. Your clients will appreciate that you respect their
							time and know how to manage your own.
						</ArticleListItem>
					</ArticleList>

					<ArticleCopy>
						As we've seen, there is a lot to gain from having effective time management
						practices. Were you surprised by some of the benefits in our list? It's
						interesting to see the impact that time management can have. If you'd like
						to start using a time management system in your workflow, then why not
						{' '}
						try LogSpace
						{' '}
						today? See what kinds of benefits you can realize.
						Need more? Click through to our free resource, <RouterLink to="/time-management-101/">Time Management 101</RouterLink>, to help you take control of your day.
					</ArticleCopy>
				</ArticleBody>
			</ArticlePage>
		</AppLayout>
	);
}
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/benefits-of-time-management/cassie-boca-rz6sgZkA9Vc-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  mountainImg: file(relativePath: {eq: "images/articles/benefits-of-time-management/benjamin-voros-phIFdC6lA4E-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 410, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  fireworksImg: file(relativePath: {eq: "images/articles/benefits-of-time-management/lavi-perchik-FCPV_n0lOxc-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 342, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
